import { getPodcast } from "application/requests/podcast";
import find           from "lodash/find";
import map            from "lodash/map";

export const next = () => {
  return {
    type: "PLAYER/NEXT"
  };
};

export const previous = () => {
  return {
    type: "PLAYER/PREVIOUS"
  };
};

export const playNext = (item) => {
  return {
    type: "PLAYER/PLAY_NEXT",
    item
  };
};

export const playAllNext = (items) => {
  return {
    type: "PLAYER/PLAY_ALL_NEXT",
    items
  };
};

export const playNow = (item) => {
  return {
    type: "PLAYER/PLAY_NOW",
    item
  };
};

export const playAllNow = (items) => {
  return {
    type: "PLAYER/PLAY_ALL_NOW",
    items
  };
};

export const closePlayer = () => {
  return {
    type: "PLAYER/CLOSE"
  };
};

export const play = () => {
  return {
    type: "PLAYER/PLAY"
  };
};

export const pause = () => {
  return {
    type: "PLAYER/PAUSE"
  };
};

export const playPlaylistItem = (item) => {
  return {
    type: "PLAYER/PLAY_PLAYLIST_ITEM",
    item
  };
};

export const removePlaylistItem = (item) => {
  return {
    type: "PLAYER/REMOVE_PLAYLIST_ITEM",
    item
  };
};

export const setCurrentTime = (currentTime) => {
  return {
    type: "PLAYER/SET_CURRENT_TIME",
    currentTime
  };
};

export const fetchPodcastAndPlayNext = (podcastId, partId) => (
  dispatch => (
    new Promise((resolve, reject) => {
      getPodcast(podcastId).then((response) => {
        if (partId === null && response.podcast.parts.length > 0) {
          dispatch(playAllNext(map(response.podcast.parts, (part) => {
            return { podcast: response.podcast, part };
          })));
        } else {
          const part = find(response.podcast.parts, localPart => localPart.id === partId) || { id: null };
          dispatch(playNext({ podcast: response.podcast, part }));
        }
      }).catch(reject);
    })
  )
);

export const fetchPodcastAndPlayNow = (podcastId, partId) => (
  dispatch => (
    new Promise((resolve, reject) => {
      getPodcast(podcastId).then((response) => {
        if (partId === null && response.podcast.parts.length > 0) {
          dispatch(playAllNow(map(response.podcast.parts, (part) => {
            return { podcast: response.podcast, part };
          })));
        } else {
          const part = find(response.podcast.parts, localPart => localPart.id === partId) || { id: null };
          dispatch(playNow({ podcast: response.podcast, part }));
        }
      }).catch(reject);
    })
  )
);
