import React, { useState }          from "react";
import PropTypes                    from "prop-types";
import Slider                       from "react-rangeslider";
import { FontAwesomeIcon }          from "@fortawesome/react-fontawesome";
import { faVolumeDown, faVolumeUp } from "@fortawesome/free-solid-svg-icons";

const PlayerVolume = ({ audioInstance }) => {
  const [volume, setVolume] = useState(80);

  const localAudioInstance = audioInstance;

  const handleVolumeChange = (value) => {
    setVolume(value);
    localAudioInstance.volume = value / 100.0;
  };

  const handleVolumeDown = (event) => {
    event.preventDefault();
    if (volume - 10 <= 0) {
      handleVolumeChange(0);
    } else {
      handleVolumeChange(volume - 10);
    }
  };

  const handleVolumeUp = (event) => {
    event.preventDefault();
    if (volume + 10 >= 100) {
      handleVolumeChange(100);
    } else {
      handleVolumeChange(volume + 10);
    }
  };

  return (
    <div className="player-volume-container d-flex align-items-center">
      <a href="#" onClick={ handleVolumeDown } className="player-volume-button">
        <FontAwesomeIcon icon={ faVolumeDown } className="mr-3" />
      </a>
      <div className="flex-fill">
        <Slider
          tooltip={ false }
          value={ volume }
          onChange={ handleVolumeChange }
        />
      </div>
      <a href="#" onClick={ handleVolumeUp } className="player-volume-button">
        <FontAwesomeIcon icon={ faVolumeUp } className="ml-3" />
      </a>
    </div>
  );
};

PlayerVolume.defaultProps = {};

PlayerVolume.propTypes = {
  audioInstance: PropTypes.object.isRequired
};

export default PlayerVolume;
