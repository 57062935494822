import React                         from "react";
import PropTypes                     from "prop-types";
import PodcastsAllYearsListContainer from "application/components/podcasts/all_years_list_container";

const PodcastsAllYearsScene = () => (
  <div className="page-container">
    <PodcastsAllYearsListContainer />
  </div>
);

export default PodcastsAllYearsScene;
