import React, { useState } from "react";
import PropTypes           from "prop-types";
import Truncate            from "react-truncate";

const ReadMore = ({ children, lines, less, more }) => {
    const [truncated, setTruncated] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const handleTruncate = (value) => {
      if (truncated !== value) {
        setTruncated(value);
      }
    };

    const toggleLines = (event) => {
      event.preventDefault();
      setExpanded(!expanded);
    };

    return (
      <React.Fragment>
        <Truncate
          lines={ !expanded && lines }
          ellipsis={(
            <span> ... <br /><a href="#" onClick={ toggleLines }>{ more }</a></span>
          )}
          onTruncate={ handleTruncate }
        >
          { children }
        </Truncate>
        { !truncated && expanded && (
          <span><br /><a href="#" onClick={ toggleLines }>{ less }</a></span>
        ) }
      </React.Fragment>
    );
};

ReadMore.defaultProps = {
  lines: 3,
  more:  "Voir plus",
  less:  "Voir moins"
};

ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
  lines:    PropTypes.number,
  less:     PropTypes.string,
  more:     PropTypes.string
};

export default ReadMore;
