import React        from "react";
import PropTypes    from "prop-types";
import classNames   from "classnames";

import "./iframe.scss";

const PodcastsIframe = ({ podcast, borderOnHover }) => (
  <div className={ classNames("embed-responsive embed-responsive-16by9", { "border-on-hover": borderOnHover, border: !borderOnHover }) }>
    <iframe className="embed-responsive-item" title="Embed" src={ `https://www.rtbf.be/embed/m?id=${podcast.rtbf_id}&autoplay=0` } scrolling="no" frameBorder="0" allowFullScreen />
  </div>
);

PodcastsIframe.defaultProps = {
  borderOnHover: false
};

PodcastsIframe.propTypes = {
  podcast:       PropTypes.object.isRequired,
  borderOnHover: PropTypes.bool
};

export default PodcastsIframe;
