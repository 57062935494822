import React, { useState }                                                        from "react";
import PropTypes                                                                  from "prop-types";
import classNames                                                                 from "classnames";
import { useDispatch, useSelector }                                               from "react-redux";
import { Link }                                                                   from "react-router-dom";
import { FontAwesomeIcon }                                                        from "@fortawesome/react-fontawesome";
import { faInfoCircle }                                                           from "@fortawesome/free-solid-svg-icons";
import { isInPlaylist, isCurrentItem as externalIsCurrentItem, isCurrentPodcast } from "application/store/player/helpers";
import { fetchPodcastAndPlayNext, fetchPodcastAndPlayNow, pause, play }           from "application/store/player/actions";
import ScoreWithTooltip                                                           from "application/components/ui/score_with_tooltip";
import PlayerButton                                                               from "application/components/player/button";

import DefaultPoster     from "application/images/default_posters/poster_default.png";
import DefaultPosterSoir from "application/images/default_posters/poster_default_soir.png";

import "./box.scss";

const PlayableItemBox = ({ imageUrl, title, score, scoreText, podcastId, partId, enqueue, small, knowMoreUrl, alwaysShowButtons, kind }) => {
  const dispatch = useDispatch();

  const playlist    = useSelector((state) => state.player.playlist);
  const currentItem = useSelector((state) => state.player.currentItem);
  const playing     = useSelector((state) => state.player.playing);

  const handlePlayNext = () => {
    dispatch(fetchPodcastAndPlayNext(podcastId, enqueue === "part" ? partId : null));
  };

  const handlePlayNow = () => {
    dispatch(fetchPodcastAndPlayNow(podcastId, enqueue === "part" ? partId : null));
  };

  const isAddButtonDisabled = () => (
    isInPlaylist(playlist, podcastId, partId)
  );

  const isCurrentItem = () => {
    if (partId) {
      return externalIsCurrentItem(currentItem, podcastId, partId);
    }
    return isCurrentPodcast(currentItem, podcastId);
  };

  const handleClickPlay = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (isCurrentItem() && playing) {
      dispatch(pause());
      return true;
    }
    if (isCurrentItem() && !playing) {
      dispatch(play());
      return true;
    }
    handlePlayNow();
    return true;
  };

  const handlePause = () => {
    dispatch(pause());
  };

  const closeIcon = <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" stroke="white" fill="white" />;

  return (
    <>
      { small && (
        <div className={ classNames("playable-item playable-item-small d-flex align-items-center", { "is-current-item": isCurrentItem() }) }>
          <a href="#" className="mr-sm-3 playable-item-title order-3 order-sm-1" onClick={ handleClickPlay }>{ title }</a>
          <div className={ classNames("mr-1 mr-sm-2 order-1 order-sm-2 playable-item-buttons-container", { "hide-default": !alwaysShowButtons }) }>
            { (!playing || !isCurrentItem()) && <PlayerButton icon="play" onClick={ handleClickPlay } className="play-now" size="xxs" /> }
            { playing && isCurrentItem() && <PlayerButton icon="pause" onClick={ handlePause } className="play-now" size="xxs" /> }
          </div>
          <div className={ classNames("order-2 mr-2 mr-sm-0 order-sm-3 playable-item-buttons-container", { "hide-default": !alwaysShowButtons }) }>
            <PlayerButton icon="plus" onClick={ handlePlayNext } className="play-next" disabled={ isAddButtonDisabled() } size="xxs" />
          </div>
        </div>
      ) }
      { !small && (
        <div className="playable-item">
          <div className={ classNames("playable-item-image-container", { "is-current-item": isCurrentItem() }) }>
            <div className={ classNames("playable-item-buttons-container d-flex", { "hide-default": !alwaysShowButtons }) }>
              <div className="flex-fill text-center">
                { (!playing || !isCurrentItem()) && <PlayerButton icon="play" onClick={ handlePlayNow } className="play-now" /> }
                { playing && isCurrentItem() && <PlayerButton icon="pause" onClick={ handlePause } className="play-now" /> }
              </div>
              <div className="flex-fill text-center">
                <PlayerButton icon="plus" onClick={ handlePlayNext } className="play-next" disabled={ isAddButtonDisabled() } />
              </div>
            </div>
            <img src={ imageUrl || ((kind === "other") ? DefaultPosterSoir : DefaultPoster) } className="img-fluid" alt={ title } />
            { knowMoreUrl && (
              <Link to={ knowMoreUrl } className="more-info">
                <FontAwesomeIcon className="icon" icon={ faInfoCircle } />
              </Link>
            ) }
          </div>
          { score && <div className="mt-3 mb-2 text-center"><ScoreWithTooltip score={ score } scoreText={ scoreText } /></div> }
          { title && <h3 className={ classNames({ "without-score": !score, "is-current-item": isCurrentItem() }) }>{ title }</h3> }
        </div>
      ) }
    </>
  );
};

PlayableItemBox.defaultProps = {
  imageUrl:          null,
  title:             null,
  score:             null,
  scoreText:         null,
  partId:            null,
  enqueue:           "part",
  small:             false,
  knowMoreUrl:       null,
  alwaysShowButtons: false
};

PlayableItemBox.propTypes = {
  imageUrl:          PropTypes.string,
  title:             PropTypes.string,
  score:             PropTypes.string,
  scoreText:         PropTypes.string,
  podcastId:         PropTypes.number.isRequired,
  partId:            PropTypes.number,
  enqueue:           PropTypes.string,
  small:             PropTypes.bool,
  knowMoreUrl:       PropTypes.string,
  alwaysShowButtons: PropTypes.bool
};

export default PlayableItemBox;
