import React, { useState, useEffect } from "react";
import PropTypes                      from "prop-types";
import { useDispatch }                from "react-redux";
import sortBy                         from "lodash/sortBy";
import find                           from "lodash/find";
import Slider                         from "react-rangeslider";
import { next, setCurrentTime }       from "application/store/player/actions";

const PlayerProgress = ({ audioInstance, currentPodcast, currentPart }) => {
  const [progress, setProgress] = useState(null);
  const [time, setTime] = useState(null);
  const dispatch = useDispatch();

  const localAudioInstance = audioInstance;

  const nextPart = () => (
    find(sortBy(currentPodcast.parts, "start_second"), part => !Number.isNaN(parseInt(part.start_second, 10)) && parseInt(part.start_second, 10) > parseInt(startSecond() || 0, 10))
  );

  const startSecond = () => (
    currentPart ? currentPart.start_second : currentPodcast.start_second
  );

  const endSecond = () => (
    nextPart() ? nextPart().start_second : audioInstance.duration
  );

  const duration = () => (
    endSecond() - (startSecond() || 0)
  );

  const onPlayerTimeUpdate = () => {
    dispatch(setCurrentTime(audioInstance.currentTime));
    if (audioInstance.currentTime >= endSecond()) {
      dispatch(next());
    } else {
      setProgress((audioInstance.currentTime - startSecond()) / duration() * 100.0);
      setTime(audioInstance.currentTime - (startSecond() || 0));
    }
  };

  useEffect(() => {
    audioInstance.addEventListener("timeupdate", onPlayerTimeUpdate);
    return () => {
      audioInstance.removeEventListener("timeupdate", onPlayerTimeUpdate);
    };
  }, []);

  useEffect(() => {
    if (Math.abs(localAudioInstance.currentTime - startSecond()) > 5) {
      localAudioInstance.currentTime = startSecond();
    }
  }, [currentPart]);

  const handleProgressChange = (value) => {
    setProgress(value);
    localAudioInstance.currentTime = startSecond() + (duration() / 100.0) * value;
  };

  const formattedTime = () => {
    if (time === null) {
      return null;
    }
    return new Date(time * 1000).toISOString().substr(11, 8);
  };

  const formattedDuration = () => {
    if (Number.isNaN(audioInstance.duration)) {
      return null;
    }
    return new Date(duration() * 1000).toISOString().substr(11, 8);
  };

  return (
    <div className="player-progress flex-fill d-flex align-items-center">
      <span className="mr-3 player-time">{ formattedTime() }</span>
      <div className="flex-fill">
        <Slider
          tooltip={ false }
          value={ progress }
          onChange={ handleProgressChange }
        />
      </div>
      <span className="ml-3 player-time">{ formattedDuration() }</span>
    </div>
  );
};

PlayerProgress.defaultProps = {
  currentPart: null
};

PlayerProgress.propTypes = {
  audioInstance:  PropTypes.object.isRequired,
  currentPodcast: PropTypes.object.isRequired,
  currentPart:    PropTypes.object
};

export default PlayerProgress;
