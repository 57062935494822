import React          from "react";
import PropTypes      from "prop-types";
import { withRouter } from "react-router-dom";
import ReactGA        from "react-ga";

class OnNavigationChange extends React.Component {
  componentDidMount() {
    const { history } = this.props;
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("UA-132140336-1");
      ReactGA.pageview(window.location.pathname);
      history.listen(() => {
        ReactGA.pageview(window.location.pathname);
      });
    }
  }

  render() {
    return null;
  }
}

OnNavigationChange.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(OnNavigationChange);
