import React, { useRef, useEffect }                                                       from "react";
import PropTypes                                                                          from "prop-types";
import { useSelector, useDispatch }                                                       from "react-redux";
import ScrollArea                                                                         from "react-scrollbar";
import classNames                                                                         from "classnames";
import { FontAwesomeIcon }                                                                from "@fortawesome/react-fontawesome";
import { faTimes, faTrophy }                                                              from "@fortawesome/free-solid-svg-icons";
import PlayerButton                                                                       from "application/components/player/button";
import { playPlaylistItem, removePlaylistItem, pause }                                    from "application/store/player/actions";
import { itemPosterUrl, itemTitle, isCurrentItem as externalIsCurrentItem, currentIndex } from "application/store/player/helpers";

import "./playlist.scss";

import DefaultPoster from "application/images/default_posters/poster_default.png";

const PlayerPlaylist = () => {
  const player      = useSelector(state => state.player);
  const playlist    = useSelector(state => state.player.playlist);
  const playing     = useSelector(state => state.player.playing);
  const currentItem = useSelector(state => state.player.currentItem);

  const scrollAreaRef = useRef(null);

  const dispatch = useDispatch();

  const removeItem = (event, item) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(removePlaylistItem(item));
  };

  const handlePlay = (item) => {
    dispatch(playPlaylistItem(item));
  };

  const handlePause = () => {
    dispatch(pause());
  };

  const isCurrentItem = item => (
    externalIsCurrentItem(currentItem, item.podcast.id, item.part.id)
  );

  useEffect(() => {
    setTimeout(() => {
      scrollAreaRef.current.scrollYTo(currentIndex(player) * 46);
    }, 200);
  }, [currentItem]);

  return (
    <div className="player-playlist-container">
      <ScrollArea
        ref={ scrollAreaRef }
        speed={ 0.8 }
        horizontal={ false }
        style={{ maxHeight: "400px" }}
      >
        <ul className="list-unstyled player-playlist-list">
          { playlist.map(item => (
            <li key={ `playlist-item-${item.podcast.id}-${item.part.id}` } className={ classNames("d-flex align-items-center mb-3 pr-3", { "is-current-item": isCurrentItem(item) }) } onClick={ () => { isCurrentItem(item) && playing ? handlePause() : handlePlay(item);  } }>
              <div className="player-playlist-item-image-container mr-2">
                <img src={ itemPosterUrl(item) || DefaultPoster } alt={ itemTitle(item) } />
                { (!playing || !isCurrentItem(item)) && <PlayerButton icon="play" active={ isCurrentItem(item) } className="play-now" size="xxs" /> }
                { playing && isCurrentItem(item) && <PlayerButton icon="pause" onClick={ handlePause } className="play-now" active={ isCurrentItem(item) } size="xxs" /> }
              </div>
              <div className="flex-fill">
                <span className={ classNames("text", { "text-is-current-item": isCurrentItem(item) }) }>{ itemTitle(item) }</span><br />
                { item.part.score_text && (
                  <div className="d-flex align-items-bottom mt-1">
                    <FontAwesomeIcon icon={ faTrophy } className="mr-2 text-xs" />
                    { item.part.score_text }
                  </div>
                ) }
              </div>
              <div>
                <a href="#" onClick={ (event) => { removeItem(event, item); } }><FontAwesomeIcon icon={ faTimes } /></a>
              </div>
            </li>
          )) }
        </ul>
      </ScrollArea>
    </div>
  );
};

export default PlayerPlaylist;
