import React, { useState, useEffect }                                        from "react";
import PropTypes                                                             from "prop-types";
import { useParams }                                                         from "react-router-dom";
import Helmet                                                                from "react-helmet";
import groupBy                                                               from "lodash/groupBy";
import includes                                                              from "lodash/includes";
import orderBy                                                               from "lodash/orderBy";
import slice                                                                 from "lodash/slice";
import find                                                                  from "lodash/find";
import Loader                                                                from "application/components/ui/loader";
import { getMovie }                                                          from "application/requests/movie";
import PlayableItemBox                                                       from "application/components/playable_item/box";
import Score                                                                 from "application/components/ui/score";
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle, CardText } from "reactstrap";

import "./show.scss";

import ActorPlaceholder from "application/images/actor-placeholder.png";

const MoviesShowScene = () => {
  const { slug } = useParams();
  const [movie, setMovie] = useState(null);
  const [loading, setLoading] = useState(true);

  const jobs = ["Director", "Producer", "Screenplay", "Writer"];

  const jobTranslations = {
    Director:   "Réalisateur",
    Producer:   "Producteur",
    Screenplay: "Scénariste",
    Writer:     "Auteur"
  };

  useEffect(() => {
    getMovie(slug).then((response) => {
      setMovie(response.movie);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loader centered />;
  }

  const criticWithScore = () => (
    find(movie.critics, (critic) => critic.score_text)
  );

  const featuredCrew = () => (
    groupBy(orderBy(movie.people.filter((person) => includes(jobs, person.job.name)), (item) => jobs.indexOf(item.job.name)), (item) => item.name)
  );

  const actors = () => (
    slice(orderBy(movie.people.filter((person) => person.job.name === "Actor"), (item) => item.position), 0, 6)
  );

  return (
    <>
      <Helmet>
        <title>{ `${movie.name} | 5heures` }</title>
      </Helmet>
      <div className="movie-header" style={ { backgroundImage: `url(${movie.backdrop_url})` }}>
        <Container className="d-flex">
          <div className="movie-header-content d-flex py-5 flex-wrap flex-md-nowrap">
            <img src={ movie.poster_url } width="300px" alt={ movie.name } className="m-auto m-md-0 mr-md-5" />
            <div className="mt-3 mt-sm-0">
              <h1>{ movie.name } <small>({ movie.year })</small></h1>
              { criticWithScore() && (
                <div className="d-flex align-items-center mb-4 flex-wrap flex-md-nowrap">
                  <Score score={ criticWithScore().score } />
                  <span className="score-text">{ criticWithScore().score_text }</span>
                </div>
              ) }
              <h2 className="mb-3">La critique d'Hugues</h2>
              <ul className="list-unstyled">
                { movie.critics.map((critic) => (
                  <li className="mb-2" key={ `critic-${critic.id}` }>
                    <PlayableItemBox alwaysShowButtons small title={ `${critic.podcast.kind_text} du ${critic.podcast_date}` } podcastId={ critic.podcast_id } partId={ critic.id } />
                  </li>
                )) }
              </ul>
              <h2 className="mt-4 mb-3">Équipe technique</h2>
              <Row>
                { Object.entries(featuredCrew()).map((itemTuple, index) => (
                  <Col sm="8" className="mb-3" key={ `person-${index}` }>
                    <span className="movie-job-label">{ itemTuple[0] }</span>
                    <span className="movie-job-value">{ itemTuple[1].map((person) => jobTranslations[person.job.name]).join(", ") }</span>
                  </Col>
                )) }
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <Container className="mt-5">
        <h2 className="mb-3">Synopsis</h2>
        <p>{ movie.overview }</p>
        <div className="movie-cast">
          <h2 className="mb-3">Acteurs principaux</h2>
          <Row>
            { actors().map((actor) => (
              <Col xs="12" sm="4" className="mb-3" key={ `actor-${actor.id}` }>
                <Card>
                  <CardImg top width="100%" src={ ActorPlaceholder } alt={ actor.name } />
                  <div className="actor-placeholder" />
                  <CardBody>
                    <CardTitle>{ actor.name }</CardTitle>
                    <CardText>{ actor.character_name }</CardText>
                  </CardBody>
                </Card>
              </Col>
            )) }
          </Row>
        </div>
      </Container>
    </>
  );
};

export default MoviesShowScene;
