import React      from "react";
import PropTypes  from "prop-types";
import classNames from "classnames";
import GridLoader from "react-spinners/GridLoader";

import "./loader.scss";

const Loader = ({ centered }) => (
  <div className={ classNames({ "loader-centered": centered }) }>
    <GridLoader
      size={ 20 }
      color="#F5C924"
      loading
    />
  </div>
);

Loader.defaultProps = {
  centered: false
};

Loader.propTypes = {
  centered: PropTypes.bool
};

export default Loader;
