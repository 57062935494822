import React, { useState, useEffect } from "react";
import PropTypes                      from "prop-types";
import { useParams }                  from "react-router-dom";
import Helmet                         from "react-helmet";
import InfiniteScroll                 from "react-infinite-scroll-component";
import { getMovies }                  from "application/requests/movie";
import Loader                         from "application/components/ui/loader";
import MoviesList                     from "application/components/movies/list";
import MoviesByScoreNavigation        from "application/scenes/movies/by_score_navigation";
import { Container }                  from "reactstrap";

const MoviesByScoreScene = () => {
  const score = useParams().score.split("-")[0];

  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ current_page: 1, total_pages: 1 });

  const fetchNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const hasMore = () => (
    currentPage < pagination.total_pages
  );

  const fetchMovies = () => {
    getMovies({ score, per_page: 15, page: currentPage }).then((response) => {
      if (currentPage === 1) {
        setMovies(response.movies);
      } else {
        setMovies(movies.concat(response.movies));
      }
      setPagination(response.pagination);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchMovies();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1) {
      setLoading(true);
      fetchMovies();
    } else {
      setCurrentPage(1);
    }
  }, [score]);

  return (
    <Container className="pt-3">
      <Helmet>
        <title>Les films { score } étoiles | 5heures</title>
      </Helmet>
      <div className="mt-4 mb-5">
        <MoviesByScoreNavigation />
      </div>
      { loading && <Loader centered /> }
      { !loading && (
        <div className="home-four-stars">
          <InfiniteScroll
            dataLength={ movies.length }
            next={ fetchNextPage }
            hasMore={ hasMore() }
            pageStart={ 1 }
            loader={ (
              <div className="text-center mt-5 mb-5 mx-auto" style={ { width: "100px" } }>
                <Loader key="loader" />
              </div>
            ) }
            pullDownToRefresh={ false }
          >
            <MoviesList movies={ movies } columns={ 3 } />
          </InfiniteScroll>
        </div>
      ) }
    </Container>
  );
};

export default MoviesByScoreScene;
