import queryString from "query-string";
import Api         from "application/services/api";

export const getMovie = slug => (
  new Promise((resolve, reject) => {
    Api.get(`/movies/${slug}`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  })
);

export const getMovies = params => (
  new Promise((resolve, reject) => {
    Api.get(`/movies?${queryString.stringify(params)}`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  })
);
