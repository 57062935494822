import React, { useEffect, useRef }                from "react";
import PropTypes                                   from "prop-types";
import { useDispatch, useSelector }                from "react-redux";
import ReactGA                                     from "react-ga";
import { FontAwesomeIcon }                         from "@fortawesome/react-fontawesome";
import { faTimes, faTrophy }                       from "@fortawesome/free-solid-svg-icons";
import { closePlayer, play, pause }                from "application/store/player/actions";
import PlayerButtons                               from "application/components/player/buttons";
import PlayerVolume                                from "application/components/player/volume";
import PlayerProgress                              from "application/components/player/progress";
import { itemPosterUrl, itemTitle, itemEventName } from "application/store/player/helpers";
import { useMediaQuery }                           from "react-responsive";
import Marquee                                     from "react-marquee";

import "./container.scss";
import "react-rangeslider/lib/index.css";

import DefaultPoster from "application/images/default_posters/poster_default.png";

const PlayerContainer = () => {
  const dispatch = useDispatch();
  const player = useSelector((state) => state.player);
  const audioInstanceRef = useRef(new Audio());
  const currentTimeFromLocalStorage = (JSON.parse(localStorage.redux) || { player: { currentTime: null } }).player.currentTime;
  const firstLoadRef = useRef(true);

  const isMobile = useMediaQuery({ maxWidth: 576 });

  const currentPodcast = () => (
    player.currentItem ? player.currentItem.podcast : null
  );

  const currentPart = () => (
    player.currentItem && player.currentItem.part.id ? player.currentItem.part : null
  );

  const handleOnPlayerCanPlayUpdate = () => {
    if (currentTimeFromLocalStorage === 0 || !firstLoadRef.current) {
      dispatch(play());
    }
    firstLoadRef.current = false;
  };

  const handlePlayFromPlayer = () => {
    dispatch(play());
  };

  const handlePauseFromPlayer = () => {
    dispatch(pause());
  };

  useEffect(() => {
    audioInstanceRef.current.addEventListener("canplay", handleOnPlayerCanPlayUpdate);
    audioInstanceRef.current.addEventListener("play", handlePlayFromPlayer);
    audioInstanceRef.current.addEventListener("pause", handlePauseFromPlayer);
    return () => {
      audioInstanceRef.current.removeEventListener("canplay", handleOnPlayerCanPlayUpdate);
      audioInstanceRef.current.removeEventListener("play", handlePlayFromPlayer);
      audioInstanceRef.current.removeEventListener("pause", handlePauseFromPlayer);
    };
  }, []);

  useEffect(() => {
    if (player.playing) {
      audioInstanceRef.current.play();
    } else {
      audioInstanceRef.current.pause();
    }
  }, [player.playing]);

  useEffect(() => {
    if (player.currentItem) {
      const srcWasNull = audioInstanceRef.current.src === "";
      if (audioInstanceRef.current.src !== currentPodcast().audio_file_url) {
        audioInstanceRef.current.src = currentPodcast().audio_file_url;
        if (srcWasNull) {
          audioInstanceRef.current.currentTime = player.currentTime;
        } else {
          audioInstanceRef.current.play();
          dispatch(play());
        }
      }
      if (audioInstanceRef.current.currentTime < ((currentPart() || currentPodcast()).start_second || 0) - 10) {
        audioInstanceRef.current.currentTime = (currentPart() || currentPodcast()).start_second || 0;
      }
      if (process.env.NODE_ENV === "production") {
        ReactGA.event({
          category: "Audio",
          action:   "Play",
          label:    itemEventName(player.currentItem)
        });
      }
    } else {
      dispatch(pause());
    }
  }, [player.currentItem]);

  const close = (event) => {
    event.preventDefault();
    dispatch(closePlayer());
  };

  const currentPictureUrl = () => (
    itemPosterUrl(player.currentItem)
  );

  const currentTitle = () => (
    itemTitle(player.currentItem)
  );

  if (!player.currentItem) {
    return null;
  }

  return (
    <div className="player-container d-flex align-items-center">
      <a href="#" onClick={ close } className="player-close"><FontAwesomeIcon icon={ faTimes } /></a>
      <img className="player-cover-image d-none d-sm-block" src={ currentPictureUrl() || DefaultPoster } alt={ currentTitle() } />
      <div className="player-title-and-score">
        <h3 className="player-title">
          <Marquee hoverToStop={ isMobile } loop text={ currentTitle() } />
        </h3>
        { currentPart() && currentPart().score_text && (
          <div className="mt-2">
            <Marquee hoverToStop={ isMobile } loop text={ <span><FontAwesomeIcon icon={ faTrophy } className="mr-2" />{ currentPart().score_text }</span> } />
          </div>
        ) }
      </div>
      <PlayerButtons audioInstance={ audioInstanceRef.current } />
      <div className="d-none d-sm-flex flex-fill">
        <PlayerProgress key={ `progress-${(currentPodcast() || {}).id}-${(currentPart() || {}).id}` } audioInstance={ audioInstanceRef.current } currentPodcast={ currentPodcast() } currentPart={ currentPart() } />
        <PlayerVolume audioInstance={ audioInstanceRef.current } />
      </div>
    </div>
  );
};

export default PlayerContainer;
