import React                 from "react";
import PropTypes             from "prop-types";
import PodcastsListContainer from "application/components/podcasts/list_container";

const PodcastsScene = () => (
  <div className="page-container">
    <PodcastsListContainer />
  </div>
);

export default PodcastsScene;
