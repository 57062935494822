import React, { useEffect, useState } from "react";
import PropTypes                      from "prop-types";
import { Link }                       from "react-router-dom";
import { useSelector }                from "react-redux";
import { FontAwesomeIcon }            from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight }        from "@fortawesome/free-solid-svg-icons";
import PodcastsIframe                 from "application/components/podcasts/iframe";
import { getLastPodcasts }            from "application/requests/podcast";
import Loader                         from "application/components/ui/loader";
import { Row, Col }                   from "reactstrap";

import "./last_podcasts.scss";

const HomeLastPodcasts = () => {
  const [podcasts, setPodcasts] = useState([]);
  const [loading, setLoading] = useState([]);

  const slugForKinds = useSelector((state) => state.application.config.slug_for_kinds);

  useEffect(() => {
    getLastPodcasts().then((response) => {
      setPodcasts(response.podcasts);
      setLoading(false);
    });
  }, []);

  return (
    <>
      { loading && <Loader /> }
      { !loading && (
        <Row className="home-last-podcasts">
          { podcasts.map((podcast) => (
            <Col md="8" className="home-last-podcasts-item" key={ `last-podcasts-${podcast.id}` }>
              <h3 className="d-flex align-items-center"><span className="flex-fill">{ podcast.kind_text }</span><small>{ podcast.date }</small></h3>
              <PodcastsIframe podcast={ podcast } borderOnHover />
              <div className="text-right mt-3">
                <Link to={ `/${slugForKinds[podcast.kind]}/${podcast.year}` }>
                  Voir tout
                  <FontAwesomeIcon icon={ faLongArrowAltRight } className="ml-2" />
                </Link>
              </div>
            </Col>
          )) }
        </Row>
      ) }
    </>
  );
};

export default HomeLastPodcasts;
