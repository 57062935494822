import React          from "react";
import PropTypes      from "prop-types";
import PodcastsIframe from "application/components/podcasts/iframe";
import { Row, Col }   from "reactstrap";
import ReadMore       from "application/components/ui/read_more";

import "./embed.scss";

import LogoRtbf       from "application/images/logo-rtbf.png";
import LogoLaPremiere from "application/images/logo-lapremiere.png";

const PodcastsEmbed = ({ podcast }) => (
  <Row className="embed-container mb-5">
    <Col md={ { size: "7", order: 1 } } xs={ { order: 2 } }>
      <PodcastsIframe podcast={ podcast } />
    </Col>
    <Col md={ { size: "17", order: 2 } } xs={ { order: 1 } } className="mb-4 mb-md-0 pt-3">
      <h2 className="embed-title mb-3">{ podcast.date }</h2>
      <p className="d-none d-sm-block">{ podcast.description }</p>
      <div className="d-sm-none">
        <ReadMore>
          { podcast.description }
        </ReadMore>
      </div>
      <Row className="align-items-center mt-3">
        <Col md="4" xs="12">
          <img src={ LogoRtbf } alt="Logo RTBF" className="logo img-fluid" />
        </Col>
        <Col md="4" xs="12">
          <img src={ LogoLaPremiere } alt="Logo La Première" className="logo ml-md-5 mt-4 mb-4 mt-md-0 mb-md-0 align-self-center" className="img-fluid" />
        </Col>
      </Row>
    </Col>
  </Row>
);

PodcastsEmbed.propTypes = {
  podcast: PropTypes.object.isRequired,
};

export default PodcastsEmbed;
