import queryString from "query-string";
import Api         from "application/services/api";

export const getPodcasts = params => (
  new Promise((resolve, reject) => {
    Api.get(`/podcasts?${queryString.stringify(params)}`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  })
);

export const getPodcast = podcastId => (
  new Promise((resolve, reject) => {
    Api.get(`/podcasts/${podcastId}`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  })
);

export const getLastPodcasts = () => (
  new Promise((resolve, reject) => {
    Api.get("/podcasts?last=true").then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  })
);

export const getPodcastsByYears = kind => (
  new Promise((resolve, reject) => {
    Api.get(`/podcasts_by_years?kind=${kind}`).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  })
);
