import React, { useState, useEffect } from "react";
import PropTypes                      from "prop-types";
import Helmet                         from "react-helmet";
import ScrollArea                     from "react-scrollbar";
import classNames                     from "classnames";
import { FontAwesomeIcon }            from "@fortawesome/react-fontawesome";
import { faTimes, faSearch }          from "@fortawesome/free-solid-svg-icons";
import useDebounce                    from "application/hooks/use_debounce";
import { getMovies }                  from "application/requests/movie";
import Loader                         from "application/components/ui/loader";
import PlayableItemBox                from "application/components/playable_item/box";
import { Row, Col }                   from "reactstrap";

import "./index.scss";

const SearchField = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchResultOpened, setSearchResultOpened] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 800);

  const performSearch = () => {
    if (searchTerm !== "") {
      setLoading(true);
      getMovies({ term: searchTerm, per_page: 18 }).then((response) => {
        setMovies(response.movies);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    } else {
      setMovies([]);
    }
  };

  useEffect(() => {
    performSearch();
  }, [debouncedSearchTerm]);

  const emptySearch = () => {
    setSearchTerm("");
    setMovies([]);
    setSearchResultOpened(false);
  };

  const toggleFocusSearchResult = (value) => {
    setSearchResultOpened(value);
    if (!value) {
      emptySearch();
    }
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="search-container">
      <div className="search-input-container">
        <input
          type="text"
          className={ classNames("search-input", { open: searchResultOpened }) }
          placeholder="Rechercher un film"
          onFocus={ () => toggleFocusSearchResult(true) }
          // onBlur={ () => toggleFocusSearchResult(false) }
          value={ searchTerm }
          onChange={ handleSearchTermChange }
        />
        { !loading && !searchTerm.length && (
          <FontAwesomeIcon icon={ faSearch } className="icon" />
        ) }
        { !loading && searchTerm.length > 0 && (
          <FontAwesomeIcon icon={ faTimes } className="icon" onClick={ emptySearch } />
        ) }
      </div>
      { searchResultOpened && (
        <React.Fragment>
          <Helmet>
            <body className="search-open" />
          </Helmet>
          <div className={ classNames("result-container", { big: movies.length > 0 }) }>
            { !movies.length && (
              <div className="search-placeholder pt-5 pb-5">
                { !loading && <React.Fragment>Cherchez un film, un réalisateur ou un acteur...</React.Fragment> }
                { loading && <Loader /> }
              </div>
            ) }
            { movies.length > 0 && !loading && (
              <ScrollArea
                speed={ 0.8 }
                horizontal={ false }
                style={{ maxHeight: "440px", width: "100%" }}
                className={ classNames("scrollable-search", { "no-search": !movies.length }) }
              >
                <Row>
                  { movies.map(movie => (
                    <Col md="4" key={ `part-${movie.id}` } className="mb-3 search-movie-box">
                      <a href={ `/films/${movie.slug}` }>
                        <img src={ movie.poster_url } alt={ movie.name } className="img-fluid" />
                        <h2 className="text-center search-movie-box-title mt-2">{ movie.name }</h2>
                      </a>
                    </Col>
                  )) }
                </Row>
              </ScrollArea>
            ) }
          </div>
          <div className="search-background" onClick={ emptySearch } onKeyPress={ emptySearch } role="button" tabIndex="0" />
        </React.Fragment>
      ) }
    </div>
  );
};

export default SearchField;
