import React               from "react";
import PropTypes           from "prop-types";
import { Link }            from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy }        from "@fortawesome/free-solid-svg-icons";
import find                from "lodash/find";
import { Row, Col }        from "reactstrap";

import "./list.scss";

import BackdropPlaceholder from "application/images/backdrop-placeholder.png";

const MoviesList = ({ movies, columns }) => {
  const criticWithScoreForMovie = movie => (
    find(movie.critics, critic => critic.score_text)
  );

  return (
    <Row className="movies-list align-items-stretch">
      { movies.map(movie => (
        <Col md={ 24 / parseInt(columns, 10) } key={ `movie-item-${movie.id}` } className="movies-list-item-container">
          <Link key="new" to={ `/films/${movie.slug}` } className="movies-list-item-link">
            <div className="movies-list-item">
              { movie.backdrop_url && <img src={ movie.backdrop_url } alt={ `${movie.name} Backdrop` } className="img-fluid" /> }
              { !movie.backdrop_url && <img src={ BackdropPlaceholder } alt={ `${movie.name} Backdrop` } className="img-fluid" /> }
              <div className="movies-list-item-content-overlay" />
              <div className="movies-list-item-content d-flex align-items-end">
                <img src={ movie.poster_url } alt={ `${movie.name} poster` } className="movies-list-item-cover" />
                <div className="ml-3">
                  <h4>{ movie.name }</h4>
                  <p>{ movie.release_date }</p>
                  { criticWithScoreForMovie(movie) && <p className="movies-list-item-content-score"><FontAwesomeIcon icon={ faTrophy } className="mr-2" />{ criticWithScoreForMovie(movie).score_text }</p> }
                </div>
              </div>
            </div>
          </Link>
        </Col>
      )) }
    </Row>
  );
};

MoviesList.defaultProps = {
  columns: 3
};

MoviesList.propTypes = {
  movies:  PropTypes.array.isRequired,
  columns: PropTypes.number
};

export default MoviesList;
