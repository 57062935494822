import update from "immutability-helper";

export const defaultApplicationState = {
  config:      {},
  currentPage: ""
};

const applicationReducer = (state = defaultApplicationState, action) => {
  switch (action.type) {
    case "CONFIG/SET":
      return update(state, {
        config: { $set: action.config }
      });
    default:
      return state;
  }
};

export default applicationReducer;
