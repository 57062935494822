import React        from "react";
import PropTypes    from "prop-types";
import Score        from "application/components/ui/score";
import ReactTooltip from "react-tooltip";

const ScoreWithTooltip = ({ score, scoreText }) => {
  return (
    <React.Fragment>
      <ReactTooltip multiline />
      <div data-tip={ scoreText }>
        <Score score={ score } />
      </div>
    </React.Fragment>
  );
};

ScoreWithTooltip.propTypes = {
  score:     PropTypes.string.isRequired,
  scoreText: PropTypes.string.isRequired
};

export default ScoreWithTooltip;
