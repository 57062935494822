import React                                                      from "react";
import PropTypes                                                  from "prop-types";
import classNames                                                 from "classnames";
import { FontAwesomeIcon }                                        from "@fortawesome/react-fontawesome";
import { faPlay, faPlus, faPause, faStepForward, faStepBackward } from "@fortawesome/free-solid-svg-icons";


import "./button.scss";

const icons = {
  play:     faPlay,
  plus:     faPlus,
  pause:    faPause,
  next:     faStepForward,
  previous: faStepBackward
};

const PlayerButton = ({ icon, onClick, className, disabled, active, size }) => {
  const handleClick = (event) => {
    event.preventDefault();
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <a href="#" className={ classNames(`player-button ${className} size-${size}`, { disabled, active }) } onClick={ handleClick }>
      <FontAwesomeIcon icon={ icons[icon] } />
    </a>
  );
};

PlayerButton.defaultProps = {
  className: "",
  onClick:   null,
  disabled:  false,
  active:    false,
  size:      null
};

PlayerButton.propTypes = {
  icon:      PropTypes.string.isRequired,
  onClick:   PropTypes.func,
  className: PropTypes.string,
  disabled:  PropTypes.bool,
  active:    PropTypes.bool,
  size:      PropTypes.string
};

export default PlayerButton;
