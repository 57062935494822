import React, { useState, useEffect } from "react";
import { useParams }                  from "react-router-dom";
import { useSelector }                from "react-redux";
import PropTypes                      from "prop-types";
import findKey                        from "lodash/findKey";
import PodcastsAllYearsList           from "application/components/podcasts/all_years_list";
import Loader                         from "application/components/ui/loader";

const PodcastsAllYearsListContainer = () => {
  const config = useSelector(state => state.application.config);
  const { kind } = useParams();
  const [loading, setLoading] = useState(true);

  const [localKind, setLocalKind] = useState(null);

  useEffect(() => {
    setLocalKind(findKey(config.slug_for_kinds, value => value === kind));
    setLoading(false);
  }, [kind]);

  if (loading) {
    return <Loader />;
  }

  return <PodcastsAllYearsList kind={ localKind } />;
};

export default PodcastsAllYearsListContainer;
