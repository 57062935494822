import axios from "axios";

const instance = axios.create({
  headers: {
    Accept: "application/json"
  },
  baseURL: "/api"
});

export default instance;
