import React, { useState, useEffect } from "react";
import PropTypes                      from "prop-types";
import sortBy                         from "lodash/sortBy";
import Helmet                         from "react-helmet";
import head                           from "lodash/head";
import { getPodcastsByYears }         from "application/requests/podcast";
import Loader                         from "application/components/ui/loader";
import PlayableItemBox                from "application/components/playable_item/box";
import { Row, Col }                   from "reactstrap";

import "./all_years_list.scss";

const PodcastsList = ({ kind }) => {
  const [loading, setLoading] = useState(true);
  const [podcastsByYears, setPodcastsByYears] = useState({});
  const [kindText, setKindText] = useState("");

  const fetchPodcasts = () => {
    getPodcastsByYears(kind).then((response) => {
      setPodcastsByYears(response.podcasts_by_years);
      setKindText(response.kind_text);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchPodcasts();
  }, [kind]);

  if (loading) {
    return <Loader centered />;
  }

  return (
    <div className="podcast-list-all-years">
      <Helmet>
        <title>{ `${kindText} | Tous les podcasts | 5heures` }</title>
      </Helmet>
      { sortBy(Object.entries(podcastsByYears), item => -parseInt(item[0], 10)).map(item => (
        <React.Fragment key={ `podcast-years-year-${item[0]}` }>
          <h2>{ item[0] }</h2>
          <Row>
            { item[1].map(podcast => (
              <Col key={ `podcast-years-${podcast.id}` } className="mb-1 col-24 col-md-6">
                <PlayableItemBox small title={ podcast.display_name } podcastId={ podcast.id } kind={ podcast.kind } />
              </Col>
            )) }
          </Row>
        </React.Fragment>
      )) }
    </div>
  );
};

PodcastsList.propTypes = {
  kind: PropTypes.string.isRequired
};

export default PodcastsList;
