import React                  from "react";
import PropTypes              from "prop-types";
import PlayableItemBox        from "application/components/playable_item/box";
import PodcastsCarouselButton from "application/components/podcasts/carousel_button";
import Slider                 from "react-slick";
import ReadMore               from "application/components/ui/read_more";

import "./item_with_parts.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PodcastsItemWithParts = ({ podcast }) => {
  const settings = {
    dots:           false,
    infinite:       false,
    speed:          500,
    slidesToShow:   7.5,
    slidesToScroll: 1,
    nextArrow:      <PodcastsCarouselButton next />,
    prevArrow:      <PodcastsCarouselButton previous />,
    responsive:     [
      {
        breakpoint: 576,
        settings:   {
          slidesToShow: 2.5
        }
      },
      {
        breakpoint: 768,
        settings:   {
          slidesToShow: 3.5
        }
      },
      {
        breakpoint: 1100,
        settings:   {
          slidesToShow: 4.5
        }
      },
      {
        breakpoint: 1300,
        settings:   {
          slidesToShow: 5.5
        }
      },
      {
        breakpoint: 1500,
        settings:   {
          slidesToShow: 6.5
        }
      }
    ]
  };

  return (
    <div className="podcast-with-parts-container">
      <h2>{ podcast.date }</h2>
      <div className="mb-5">
        <ReadMore>
          { podcast.description }
        </ReadMore>
      </div>
      <Slider { ...settings }>
        { podcast.parts.map(part => (
          <div className="pr-lg-5 pr-3" key={ `podcast-${podcast.id}-part-${part.id}` }>
            <PlayableItemBox
              imageUrl={ part.poster_url }
              title={ part.display_name }
              score={ part.score }
              scoreText={ part.score_text }
              podcastId={ podcast.id }
              partId={ part.id }
              kind={ podcast.kind }
              enqueue={ part.kind === "introduction" ? "podcast" : "part" }
              knowMoreUrl={ part.subject_kind === "movie" ? `/films/${part.slug}` : null }
            />
          </div>
        )) }
      </Slider>
    </div>
  );
};

PodcastsItemWithParts.propTypes = {
  podcast: PropTypes.object.isRequired,
};

export default PodcastsItemWithParts;
