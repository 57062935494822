import React                             from "react";
import PropTypes                         from "prop-types";
import classNames                        from "classnames";
import { FontAwesomeIcon }               from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import "./carousel_button.scss";

const PodcastsCarouselButton = ({ next, previous, onClick, className }) => {
  const handleClick = (event) => {
    event.preventDefault();
    onClick();
  };

  const isDisabled = () => (
    className.includes("slick-disabled")
  );

  if (isDisabled()) {
    return null;
  }

  return (
    <a
      href="#"
      className={ classNames("carousel-button", { "carousel-button-next": next, "carousel-button-previous": previous }) }
      onClick={ handleClick }
    >
      <FontAwesomeIcon icon={ next ? faChevronRight : faChevronLeft } className="valign text-center" />
    </a>
  );
};

PodcastsCarouselButton.defaultProps = {
  next:      false,
  previous:  false,
  onClick:   null,
  className: ""
};

PodcastsCarouselButton.propTypes = {
  next:      PropTypes.bool,
  previous:  PropTypes.bool,
  onClick:   PropTypes.func,
  className: PropTypes.string
};

export default PodcastsCarouselButton;
