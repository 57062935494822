import React, { useState, useEffect } from "react";
import { useParams }                  from "react-router-dom";
import { useSelector }                from "react-redux";
import PropTypes                      from "prop-types";
import head                           from "lodash/head";
import findKey                        from "lodash/findKey";
import PodcastsList                   from "application/components/podcasts/list";
import Loader                         from "application/components/ui/loader";

const PodcastsListContainer = () => {
  const config = useSelector(state => state.application.config);
  const { kind, year } = useParams();
  const [loading, setLoading] = useState(true);

  const [localKind, setLocalKind] = useState(null);
  const [localYear, setLocalYear] = useState(null);

  useEffect(() => {
    if (kind === undefined) {
      setLocalKind(findKey(config.slug_for_kinds, value => value === "le-podcast"));
      setLocalYear(head(config.years_for_kinds.cinq_heures));
    } else {
      setLocalKind(findKey(config.slug_for_kinds, value => value === kind));
      setLocalYear(year);
    }
    setLoading(false);
  }, [kind, year]);

  if (loading) {
    return <Loader />;
  }

  return <PodcastsList kind={ localKind } year={ localYear.toString(10) } />;
};

export default PodcastsListContainer;
