import update                                               from "immutability-helper";
import head                                                 from "lodash/head";
import uniqBy                                               from "lodash/uniqBy";
import { currentIndex, hasNext, hasPrevious, isInPlaylist } from "application/store/player/helpers";

export const defaultPlayerState = {
  playlist:    [],
  currentItem: null,
  playing:     false,
  currentTime: 0
};

const playerReducer = (state = defaultPlayerState, action) => {
  switch (action.type) {
    case "PLAYER/NEXT":
      if (hasNext(state)) {
        return update(state, {
          currentItem: { $set: state.playlist[currentIndex(state) + 1] }
        });
      }
      return update(state, {
        playlist:    { $set: [] },
        currentItem: { $set: null }
      });
    case "PLAYER/PREVIOUS":
      if (hasPrevious(state)) {
        return update(state, {
          currentItem: { $set: state.playlist[currentIndex(state) - 1] }
        });
      }
      return state;
    case "PLAYER/PLAY_NEXT":
      return update(state, {
        playlist: { $push: [action.item] },
      });
    case "PLAYER/PLAY_ALL_NEXT":
      return update(state, {
        playlist: { $set: uniqBy(state.playlist.concat(action.items), item => `${item.podcast.id}-${item.part.id}`) }
      });
    case "PLAYER/PLAY_NOW":
      if (state.currentItem && action.item.podcast.id === state.currentItem.podcast.id && action.item.part.id === state.currentItem.part.id) {
        return update(state, {
          playing: { $set: true }
        });
      }
      if (isInPlaylist(state.playlist, action.item.podcast.id, action.item.part.id)) {
        return update(state, {
          currentItem: { $set: action.item }
        });
      }
      return update(state, {
        currentItem: { $set: action.item },
        playlist:    { $unshift: [action.item] }
      });
    case "PLAYER/PLAY_ALL_NOW":
      return update(state, {
        currentItem: { $set: head(action.items) },
        playlist:    { $set: uniqBy(action.items.concat(state.playlist), item => `${item.podcast.id}-${item.part.id}`) }
      });
    case "PLAYER/PLAY_FROM_PLAYLIST":
      return update(state, {
        currentItem: { $set: state.playlist[action.index] }
      });
    case "PLAYER/CLOSE":
      return defaultPlayerState;
    case "PLAYER/PLAY":
      return update(state, {
        playing: { $set: true }
      });
    case "PLAYER/PAUSE":
      return update(state, {
        playing: { $set: false }
      });
    case "PLAYER/SET_CURRENT_TIME":
      return update(state, {
        currentTime: { $set: action.currentTime }
      });
    case "PLAYER/PLAY_PLAYLIST_ITEM":
      return update(state, {
        currentItem: { $set: action.item },
        playing:     { $set: true }
      });
    case "PLAYER/REMOVE_PLAYLIST_ITEM":
      return update(state, {
        currentItem: { $set: state.currentItem.podcast.id === action.item.podcast.id && state.currentItem.part.id === action.item.part.id ? state.playlist[currentIndex(state) + 1] : state.currentItem },
        playlist:    { $set: state.playlist.filter(localItem => localItem.podcast.id !== action.item.podcast.id || localItem.part.id !== action.item.part.id) }
      });
    default:
      return state;
  }
};

export default playerReducer;
