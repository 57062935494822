import React                     from "react";
import PropTypes                 from "prop-types";
import times                     from "lodash/times";
import { FontAwesomeIcon }       from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar }     from "@fortawesome/free-regular-svg-icons";

import "./score.scss";

const Score = ({ score }) => {
  const scoreInteger = parseInt(score, 10);

  if (Number.isNaN(Number(scoreInteger))) {
    return null;
  }

  const array = [];
  let i = 0;

  times(scoreInteger, () => {
    i += 1;
    array.push(<FontAwesomeIcon icon={ faStar } key={ i } />);
  });

  if (score && (parseFloat(score.replace(",", ".")) - parseFloat(scoreInteger)) > 0) {
    array.push(<FontAwesomeIcon icon={ faStarHalfAlt } key={ i + 1 } />);
    i += 1;
  }

  while (i < 4) {
    array.push(<FontAwesomeIcon icon={ farStar } key={ i + 1 } />);
    i += 1;
  }

  return (
    <div className="score-stars">
      { array }
    </div>
  );
};

Score.propTypes = {
  score: PropTypes.string.isRequired
};

export default Score;
