import React, { useState, useEffect } from "react";
import PropTypes                      from "prop-types";
import { useDispatch }                from "react-redux";
import { setConfig }                  from "application/store/application/actions";
import { getConfig }                  from "application/requests/config";

const WithConfig = ({ children }) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getConfig().then((response) => {
      dispatch(setConfig(response.config));
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return null;
  }

  return children;
};

WithConfig.propTypes = {
  children: PropTypes.array.isRequired
};

export default WithConfig;
