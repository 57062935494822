import React                      from "react";
import PropTypes                  from "prop-types";
import { Provider }               from "react-redux";
import thunk                      from "redux-thunk";
import persistState               from "redux-localstorage";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";

import applicationReducer from "application/store/application/reducer";
import playerReducer      from "application/store/player/reducer";

const WithStore = ({ children }) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const allReducers = combineReducers({
    application: applicationReducer,
    player:      playerReducer
  });

  function myCustomSlicer() {
    return (state) => {
      const subset = { player: {
        playlist:    state.player.playlist,
        currentItem: state.player.currentItem,
        playing:     false,
        currentTime: state.player.currentTime
      } };
      return subset;
    };
  }

  const store = createStore(
    allReducers,
    composeEnhancers(
      applyMiddleware(thunk),
      persistState(["player"], { slicer: myCustomSlicer })
    )
  );

  return (
    <React.Fragment>
      <Provider store={ store }>
        { children }
      </Provider>
    </React.Fragment>
  );
};

WithStore.propTypes = {
  children: PropTypes.object.isRequired
};

export default WithStore;
