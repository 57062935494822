import React, { useState, useEffect }                                     from "react";
import PropTypes                                                          from "prop-types";
import { useSelector, useDispatch }                                       from "react-redux";
import { FontAwesomeIcon }                                                from "@fortawesome/react-fontawesome";
import { faList }                                                         from "@fortawesome/free-solid-svg-icons";
import { next, previous, play, pause }                                    from "application/store/player/actions";
import PlayerButton                                                       from "application/components/player/button";
import PlayerPlaylist                                                     from "application/components/player/playlist";
import { hasNext as externalHasNext, hasPrevious as externalhasPrevious } from "application/store/player/helpers";
import { Popover, PopoverBody }                                           from "reactstrap";

import "./buttons.scss";

const PlayerButtons = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const dispatch = useDispatch();

  const playing     = useSelector(state => state.player.playing);
  const playlist    = useSelector(state => state.player.playlist);
  const hasNext     = useSelector(state => externalHasNext(state.player));
  const hasPrevious = useSelector(state => externalhasPrevious(state.player));

  const handleClickOutside  = (event) => {
    if ($(event.target).data("toggle") !== "popover" && $(event.target).parents(".popover").length === 0) {
      setIsPopoverOpen(false);
    }
  };

  useEffect(() => {
    $("body").on("click", handleClickOutside);
    return () => {
      $("body").off("click", handleClickOutside);
    };
  }, []);

  const handlePlay = () => {
    dispatch(play());
  };

  const handlePause = () => {
    dispatch(pause());
  };

  const handleNext = () => {
    dispatch(next());
  };

  const handlePrevious = () => {
    dispatch(previous());
  };

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  return (
    <div className="player-buttons d-flex align-items-center">
      <PlayerButton icon="previous" onClick={ handlePrevious } className="mr-2" disabled={ !hasPrevious } />
      { !playing && <PlayerButton icon="play" onClick={ handlePlay } /> }
      { playing && <PlayerButton icon="pause" onClick={ handlePause } /> }
      <PlayerButton icon="next" onClick={ handleNext } className="ml-2" disabled={ !hasNext } />
      <div className="d-none d-sm-block">
        <div className="playlist-container ml-4" id="popover-link">
          <a href="#" onClick={ (event) => { event.preventDefault(); setIsPopoverOpen(!isPopoverOpen); } }>
            <FontAwesomeIcon icon={ faList } className="playlist-icon" />
            <span className="playlist-count">{ playlist.length }</span>
          </a>
        </div>
        <Popover placement="bottom" isOpen={ isPopoverOpen } target="popover-link" className="playlist-popover" toggle={ togglePopover }>
          <PopoverBody>
            <PlayerPlaylist onClose={ () => { setIsPopoverOpen(false); } } />
          </PopoverBody>
        </Popover>
      </div>
    </div>
  );
};

export default PlayerButtons;
