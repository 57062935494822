import Api from "application/services/api";

export const getConfig = () => (
  new Promise((resolve, reject) => {
    Api.get("/config").then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  })
);
