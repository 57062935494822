import React               from "react";
import PropTypes           from "prop-types";
import { NavLink }         from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar }          from "@fortawesome/free-solid-svg-icons";
import { Nav, NavItem }    from "reactstrap";

const MoviesByScoreNavigation = () => {
  return (
    <Nav tabs>
      <NavItem>
        <NavLink to="/films/score/4-etoiles" className="nav-link" activeClassName="active">
          <span className="mr-2">4</span>
          <FontAwesomeIcon icon={ faStar } />
          <FontAwesomeIcon icon={ faStar } />
          <FontAwesomeIcon icon={ faStar } />
          <FontAwesomeIcon icon={ faStar } />
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/films/score/3-etoiles" className="nav-link" activeClassName="active">
          <span className="mr-2">3</span>
          <FontAwesomeIcon icon={ faStar } />
          <FontAwesomeIcon icon={ faStar } />
          <FontAwesomeIcon icon={ faStar } />
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/films/score/2-etoiles" className="nav-link" activeClassName="active">
          <span className="mr-2">2</span>
          <FontAwesomeIcon icon={ faStar } />
          <FontAwesomeIcon icon={ faStar } />
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default MoviesByScoreNavigation;
