import React, { useState, useEffect } from "react";
import PropTypes                      from "prop-types";
import { useSelector }                from "react-redux";
import InfiniteScroll                 from "react-infinite-scroll-component";
import head                           from "lodash/head";
import find                           from "lodash/find";
import filter                         from "lodash/filter";
import includes                       from "lodash/includes";
import { Link }                       from "react-router-dom";
import Helmet                         from "react-helmet";
import classNames                     from "classnames";
import { getPodcasts }                from "application/requests/podcast";
import Loader                         from "application/components/ui/loader";
import PodcastsEmbed                  from "application/components/podcasts/embed";
import PodcastsItemWithParts          from "application/components/podcasts/item_with_parts";
import PlayableItemBox                from "application/components/playable_item/box";
import { Col }                        from "reactstrap";

import "./list.scss";

const PodcastsList = ({ kind, year }) => {
  const [loading, setLoading] = useState(true);
  const [podcasts, setPodcasts] = useState([]);
  const [lastPodcast, setLastPodcast] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ current_page: 1, total_pages: 1 });

  const kindSlug = useSelector((state) => state.application.config.slug_for_kinds[kind]);
  const availableYears = useSelector((state) => state.application.config.years_for_kinds[kind]);

  const fetchPodcasts = () => {
    if (currentPage === 1) {
      setLoading(true);
    }
    getPodcasts({ kind, year, page: currentPage, per_page: kind === "other" ? 40 : 5 }).then((response) => {
      const podcastWithoutLastOne = filter(response.podcasts, (podcast) => !podcast.last);
      if (currentPage === 1) {
        setLastPodcast(find(response.podcasts, (podcast) => podcast.last));
        setPodcasts(podcastWithoutLastOne);
      } else {
        setPodcasts(podcasts.concat(podcastWithoutLastOne));
      }
      setPagination(response.pagination);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchPodcasts();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1) {
      fetchPodcasts();
    } else {
      setCurrentPage(1);
    }
  }, [kind, year]);

  const fetchNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const hasMore = () => (
    currentPage < pagination.total_pages
  );

  if (loading) {
    return <Loader centered />;
  }

  return (
    <>
      <Helmet>
        <title>{ (podcasts.length > 0 ? head(podcasts) : lastPodcast).kind_text } | { year } | 5heures</title>
      </Helmet>
      { lastPodcast && <PodcastsEmbed podcast={ lastPodcast } /> }
      <InfiniteScroll
        dataLength={ podcasts.length }
        next={ fetchNextPage }
        hasMore={ hasMore() }
        pageStart={ 1 }
        className={ classNames({ row: kind === "other" }) }
        loader={ (
          <div className="text-center mt-5 mb-5 mx-auto" style={ { width: "100px" } }>
            <Loader key="loader" />
          </div>
        ) }
        pullDownToRefresh={ false }
      >
        { kind !== "other" && (
          <>
            { podcasts.map((podcast) => (
              <React.Fragment key={ `podcast-${podcast.id}` }>
                { !podcast.last && <PodcastsItemWithParts podcast={ podcast } /> }
              </React.Fragment>
            )) }
          </>
        ) }
        { kind === "other" && (
          <>
            { podcasts.map((podcast) => (
              <Col xs="12" md="3" key={ `podcast-${podcast.id}` } className="mb-5">
                <PlayableItemBox imageUrl={ podcast.poster_url } title={ podcast.date } podcastId={ podcast.id } kind={ podcast.kind } />
              </Col>
            )) }
          </>
        ) }
      </InfiniteScroll>
      { includes(availableYears, year - 1) && !hasMore() && (
        <div className="text-center see-next-podcasts mt-5">
          <Link to={ `/${kindSlug}/${year - 1}` }>
            { `Voir les podcasts de l'année ${ year - 1 }` }
          </Link>
        </div>
      ) }
    </>
  );
};

PodcastsList.propTypes = {
  kind: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired
};

export default PodcastsList;
